.a-pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px 0 10px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__dropdown {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-family: "TTCommons-Regular";
      margin-top: 4px;
      color: var(--primary-dark-color);
    }
    > div {
      width: 125px;
      > div {
        margin: 0 0 0 0;
      }
    }
  }
  &__body {
    button {
      font-family: "TTCommons-Medium" !important;
    }
    nav {
      ul {
        @media (max-width: 767px) {
          justify-content: center;
          margin-top: 15px;
        }
      }
    }
  }
}
